const companyCarType = {
  "TYPES" : [
    {
      "Value" : 'SEDAN',
      "Title" : 'SEDAN',
    },
    {
      "Value" : 'VITO',
      "Title" : 'VITO',
    },
    {
      "Value" : 'MINIBUS',
      "Title" : 'MINIBUS',
    },
    {
      "Value" : 'OTOBUS',
      "Title" : 'OTOBUS',
    },
    ],
};
export default companyCarType;
