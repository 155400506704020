
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import {ErrorMessage, Field, Form} from "vee-validate";
import companyCarType from "@/core/data/companycartype";
import {useRoute} from "vue-router";

interface addCars {
    carTitle: string;
    carType: string;
    carPlate: string;
    carCapacity: string;

}

export default defineComponent({
    name: "operation-transfer-company-car-add",
    components: {
        Field,
        Form,
        ErrorMessage,
    },


    props: {
        carID: String,
    },

    setup(props) {

        var paramCompanyID = "";

        onMounted(() => {
            const route = useRoute();


            console.log("PARAMS route.params: "+JSON.stringify(route.params));


            if (route.params.id!==undefined && route.params.id !== null) {
                console.log("!!!! PARAM FOUND (id) !!! "+route.params.id);
                paramCompanyID = route.params.id+"";
            }



            setCurrentPageBreadcrumbs("Transfer Firmaları", ["Operasyon"]);
        });
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);



        const addCars = ref<addCars>({
            carTitle: "",
            carType: "",
            carPlate: "",
            carCapacity: "",
        });

        const validationSchema = Yup.object().shape({
            carTitle: Yup.string().required("Doldurulması zorunlu"),
            carType: Yup.string().required("Doldurulması zorunlu"),
            carPlate: Yup.string().required("Doldurulması zorunlu"),
            carCapacity: Yup.string().required("Doldurulması zorunlu"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }


            const payload1 = {
                Title: addCars.value.carTitle,
                PlateNumber: addCars.value.carPlate,
                Type: addCars.value.carType,
                Capacity: addCars.value.carCapacity,
                CompanyID: paramCompanyID,
            }


            store.dispatch(Actions.TRANSFER_CARS_INSERT, payload1)
                .then(() => {
                    store.dispatch(Actions.TRANSFER_CARS_LIST, {CompanyID: paramCompanyID});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                        // Go to page after successfully login
                        //router.push({name: "userlist"});
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getUserErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });
        }

        return {
            submitButtonRef,
            addCars,
            newRoomModalRef,
            validationSchema,
            submit,
            companyCarType,

        };
    },
});
