
import {computed, defineComponent, ref, watch} from "vue";
import {Field, Form} from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import curList from "@/core/data/currency";
import unitList from "@/core/data/units";
import {hideModal} from "@/core/helpers/dom";
import AutoComplete from "@/components/common/AutoComplete.vue";


interface newItemData {
  itemTransferFrom: string;
  itemTransferTo: string;
  itemCarCur: string;
  itemCarCost: string;
  itemCarID: string;
}

export default defineComponent({
  name: "Company-Car-Lists",

  components: {
    Field,
    Form,
    AutoComplete,
  },
  props: {
    listData: Object,
    itemID: String,
  },
  methods: {},



  setup(props) {
    watch(
        () => props.itemID,
        () => {
          store.dispatch(Actions.TRANSFER_CARCOSTS_LIST, {'CarID': props.itemID});
        }
    );


    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);

    const newItemData = ref<newItemData>({
      itemTransferFrom: "",
      itemTransferTo: "",
      itemCarCur: "",
      itemCarCost: "",
      itemCarID: "",
    });

    const myListDetails = computed(() => {
      return store.getters.transferCarCostsDetails;
    });

    const payload1 = {
      CarID: props.itemID,
    }
    const myPriceList = computed(() => {
      return store.getters.transferCarCostsList;
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      const payload1 = {
        CarID: props.itemID,
        TransferFrom: newItemData.value.itemTransferFrom,
        TransferTo: newItemData.value.itemTransferTo,
        Cur: newItemData.value.itemCarCur,
        Cost: newItemData.value.itemCarCost,

      }
      console.log(payload1);

      store.dispatch(Actions.TRANSFER_CARCOSTS_INSERT, payload1)
          .then(() => {
            store.dispatch(Actions.TRANSFER_CARCOSTS_LIST, {CarID: props.itemID});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getTransferCarCostsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");

      });
    };

    const update = (key) => {


      if (!myPriceList.value.data[key].TransferFrom) {
        Swal.fire({
          text: "Lütfen 'Nereye' bilgisini Giriniz",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return;
      }
      //Disable button

      // Activate indicator
      let idBtn = "btnItemUpdate" + key;
      let button = document.getElementById(idBtn) as HTMLElement;
      button.setAttribute('data-kt-indicator', 'on');
      const payload1 = {
        ID: myPriceList.value.data[key].ID,
        Cur: myPriceList.value.data[key].Cur,
        Cost: myPriceList.value.data[key].Cost,
      }
      console.log(payload1);

      store.dispatch(Actions.TRANSFER_CARCOSTS_EDIT, payload1)
          .then(() => {
            store.dispatch(Actions.TRANSFER_CARCOSTS_LIST, {CarID: props.itemID});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              button.setAttribute('data-kt-indicator', 'off');
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getTransferCarCostsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        button.setAttribute('data-kt-indicator', 'off');
      });

    };

    const updateActive = (ID, Active) => {
      const payload = {
        ID: ID,
        is_active: (Active == 'active') ? "passive" : "active",
        CarID: props.itemID,
      }
      store.dispatch(Actions.TRANSFER_CARCOSTS_EDIT, payload)
    }
    const deleteItem = (ID) => {
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload = {
            ID: ID,
          }
          store.dispatch(Actions.TRANSFER_CARCOSTS_DELETE, payload)
              .then(() => {
                store.dispatch(Actions.TRANSFER_CARCOSTS_LIST, {CarID: props.itemID,});
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getTransferCarCostsErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });

    }




    /************************************************/
    /************************************************/
    /************************************************/
    /************************************************/



    const lstLocations = computed(() => {
      return store.getters.transferLocationsList;
    });
    store.dispatch(Actions.TRANSFER_LOCATIONS_LIST, {});
    

    function keyupAutoTransferLocationFrom(prmInputValue) {
      return true;
    };

    function itemProjectionTransferLocationsFrom(prmItem) {
      return prmItem.name;
    };

    function selectItemAutoTransferLocationsFrom(prmItem) {
      newItemData.value.itemTransferFrom = prmItem.id;
      /*ctx.emit('TransferLocationID', prmItem);*/
      console.log(prmItem);
      let payload = {
        LocationID: prmItem.id,
        is_active: "active"
      }
    };
    
    const lstAutoCompleteItemsTransferLocationsFrom = computed(() => {
      let arrTmp = [];
      if (lstLocations.value !== undefined && lstLocations.value.data !== undefined) {
        for (let each of lstLocations.value.data) {
          arrTmp.push({"id": each.ID, "name": each.Title});
        }
      }
      return arrTmp;
    });




    function keyupAutoTransferLocationTo(prmInputValue) {
      return true;
    };

    function itemProjectionTransferLocationsTo(prmItem) {
      return prmItem.name;
    };

    function selectItemAutoTransferLocationsTo(prmItem) {
      newItemData.value.itemTransferTo = prmItem.id;
      /*ctx.emit('TransferLocationID', prmItem);*/
      console.log(prmItem);
      let payload = {
        LocationID: prmItem.id,
        is_active: "active"
      }
    };

    const lstAutoCompleteItemsTransferLocationsTo = computed(() => {
      let arrTmp = [];
      if (lstLocations.value !== undefined && lstLocations.value.data !== undefined) {
        for (let each of lstLocations.value.data) {
          arrTmp.push({"id": each.ID, "name": each.Title});
        }
      }
      return arrTmp;
    });

    /************************************************/
    /************************************************/
    /************************************************/
    /************************************************/




    return {
      lstAutoCompleteItemsTransferLocationsFrom,
      itemProjectionTransferLocationsFrom,
      keyupAutoTransferLocationFrom,
      selectItemAutoTransferLocationsFrom,
      lstAutoCompleteItemsTransferLocationsTo,
      itemProjectionTransferLocationsTo,
      keyupAutoTransferLocationTo,
      selectItemAutoTransferLocationsTo,

      newItemData,
      newRoomModalRef,
      curList,
      unitList,
      myPriceList,
      update,
      submit,
      updateActive,
      deleteItem,
      submitButtonRef,
    };
  },
});
