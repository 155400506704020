
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import {ErrorMessage, Field, Form} from "vee-validate";
import companyCarType from "@/core/data/companycartype";


export default defineComponent({
    name: "operation-transfer-company-update",
    components: {
        Field,
        Form,
        ErrorMessage,
    },


    props: {
        itemID: String,
    },



    setup(props) {

        watch(
            () => props.itemID,
            () => {
                store.dispatch(Actions.TRANSFER_CARS_DETAILS, {'ID': props.itemID});
            }
        );


        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);

        const myDetails = computed(() => {
            return store.getters.transferCarsDetails;
        })

        const validationSchema = Yup.object().shape({
            Title: Yup.string().required("Doldurulması zorunlu"),
            PlateNumber: Yup.string().required("Doldurulması zorunlu"),
            Type: Yup.string().required("Doldurulması zorunlu"),
            Capacity: Yup.string().required("Doldurulması zorunlu"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }


            const payload1 = {
                ID: props.itemID,
                Title: myDetails.value.Title,
                PlateNumber: myDetails.value.PlateNumber,
                Type: myDetails.value.Type,
                Capacity: myDetails.value.Capacity,
                CompanyID: myDetails.value.CompanyID,
            }


            store.dispatch(Actions.TRANSFER_CARS_EDIT, payload1)
                .then(() => {
                    store.dispatch(Actions.TRANSFER_CARS_LIST, {CompanyID: myDetails.value.CompanyID});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                        // Go to page after successfully login
                        //router.push({name: "userlist"});
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getUserErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });
        }

        return {
            submitButtonRef,
            newRoomModalRef,
            validationSchema,
            submit,
            myDetails,
            companyCarType,

        };
    },
});
