
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import Update from "@/components/operations/transfers/companies/companycars/Update.vue";
import PriceList from "@/components/operations/transfers/companies/companycars/PriceList.vue";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";


export default defineComponent({
    name: "operation-transfer-cars",
    components: {
        Update,
        PriceList,
    },
    props: {
        itemID: String
    },
    setup(props) {

        const payloadCL = {
            CompanyID: props.itemID
        };
        store.dispatch(Actions.TRANSFER_CARS_LIST, payloadCL);
        const myTransferCars = computed(() => {
            return store.getters.transferCarsList;
        })

        const deleteCar = (ID) => {
            store.dispatch(Actions.TRANSFER_CARS_LIST, {});
            Swal.fire({
                title: 'Emin misiniz?',
                text: "Kaydi silmek işlemi geri alınamaz.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Kaydı Sil',
                cancelButtonText: `Vazgeç`,
                confirmButtonColor: '#9c1d1d',
                cancelButtonColor: '#3085d6',
            }).then(function (result) {
                if (result.isConfirmed) {
                    const payload1 = {
                        ID: ID,
                    }
                    store.dispatch(Actions.TRANSFER_CARS_DELETE, payload1)
                        .then(() => {
                            Swal.fire(
                                'Silindi!',
                                'Kayit silme işlemi tamamlandı.',
                                'success'
                            )
                        }).catch(() => {
                        Swal.fire({
                            text: store.getters.getTransferCarsErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                }
            });
        };


        onMounted(() => {
            setCurrentPageBreadcrumbs("Transfer Firmaları", ["Operasyon"]);
        });

        const SelItemID = ref<number>(0);
        function setItems(ID) {
            SelItemID.value=ID;
        }

        const CarItemID = ref<number>(0);
        function addCost(ID) {
            CarItemID.value=ID;
        }



        return {
            CarItemID,
            addCost,
            SelItemID,
            setItems,
            myTransferCars,
            deleteCar,

        };
    },
});
