
import { defineComponent, onMounted,} from "vue";
import {useRoute} from "vue-router";
/*import { Form} from "vee-validate";*/
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import MasterConfig from "@/core/config/MasterConfig";
import {dateConvert} from "@/core/filters/datatime";
import {commonf} from "@/core/filters/common";
import Update from "@/components/operations/transfers/companies/Update.vue";
import CarList from "@/components/operations/transfers/companies/companycars/List.vue";
import CompanyCarAdd from "@/components/operations/transfers/companies/companycars/AddNew.vue";

export default defineComponent({
  name: "account-settings",
  data: function () {
    return {
    }
  },
  components: {
    Update,
    CarList,
    CompanyCarAdd,
  },
  methods: {

  },
  computed: {},
  setup() {



    const route = useRoute();
    const id = route.params.id;
    const payload = {
      ID: id
    }



    onMounted(() => {
      setCurrentPageBreadcrumbs("Özellikler", ["Oteller"]);
    });





    return {
      id,
      MasterConfig,

    };
  },
});
